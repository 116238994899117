import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class BaseCacheService {
  private cache = new Map<string, any>();
  private maxCacheSize = 100;

  protected getCacheKey(params: any): string {
    return JSON.stringify(params);
  }

  protected cacheOrFetch(endpoint: string, params: any, fetchMethod: (endpoint: string, params: any) => Observable<any>): Observable<any> {
    const cacheKey = this.getCacheKey(params);
    
    if (this.cache.has(cacheKey)) {
      return of(this.cache.get(cacheKey));
    }

    return fetchMethod(endpoint, params).pipe(
      tap(data => {
        if (this.cache.size >= this.maxCacheSize) {
          const firstKey = this.cache.keys().next().value;
          if (firstKey !== undefined) {
            this.cache.delete(firstKey);
          }
        }
        this.cache.set(cacheKey, data);
      })
    );
  }

  public hasAnyCache(): boolean {
    return this.cache.size > 0;
  }

  public clearAllCache(): void {
    this.cache.clear();
  }
}
