import { createReducer, on } from '@ngrx/store';

import { DataZoomEvent } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.component';
import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import {
  DEFAULT_DEMAND_ACTUAL_X_RESPONSE,
  DEFAULT_DEMAND_CHART_RESPONSE,
  IDemandActualXResponse,
  IDemandChartResponse,
  IReducedHistoricalDemandByProxy,
  LOAD_DEMAND_ACTUAL_X_DATA,
  LOAD_DEMAND_ACTUAL_X_DATA_FAILED,
  LOAD_DEMAND_ACTUAL_X_DATA_SUCCESS,
  LOAD_DEMAND_BY_PROXY_DATA,
  LOAD_DEMAND_BY_PROXY_DATA_FAILED,
  LOAD_DEMAND_BY_PROXY_DATA_SUCCESS,
  LOAD_DEMAND_CHART_DATA,
  LOAD_DEMAND_CHART_DATA_FAILED,
  LOAD_DEMAND_CHART_DATA_SUCCESS,
  LOAD_DEMAND_IMPACTS_CHART_DATA,
  LOAD_DEMAND_IMPACTS_CHART_DATA_FAILED,
  LOAD_DEMAND_IMPACTS_CHART_DATA_SUCCESS,
  LOAD_DEMAND_MULTI_ACTUAL_X_DATA,
  LOAD_DEMAND_MULTI_ACTUAL_X_DATA_FAILED,
  LOAD_DEMAND_MULTI_ACTUAL_X_DATA_SUCCESS,
  SET_DECIMAL_PLACES,
  SET_NUMERIC_SCALE,
  UNLOAD_DEMAND_ACTUAL_X_DATA,
  UPDATE_DATE_AGGREGATION,
  UPDATE_DATE_ZOOM,
  UPDATE_DEMAND_ANALYTIC_GROUPING,
  UPDATE_DEMAND_DATASET_YEAR_GROUPING,
  UPDATE_DEMAND_GROUPING,
  UPDATE_UOM,
  UPDATE_DEMAND_LOADED_PARAMS,
  LOAD_LIST_CURRENCIES,
  LOAD_LIST_CURRENCIES_SUCCESS,
  LOAD_LIST_CURRENCIES_FAILED,
  UPDATE_KPI_TRACKER_GROUPING,
  UPDATE_KPI_TRACKER_DATASET_YEAR_GROUPING,
  UPDATE_KPI_TRACKER_ANALYTIC_GROUPING,
} from './demand-planning.actions';
import { Currency, removeRow, removeTreeElement, updateOrAddRow, updateOrAddTreeElement } from './demand-planning.utils';
import { START_CHECKING_PENDING_DEMANDS_SHAPER_TASKS, DEMANDS_SHAPER_TASKS_RAN_SUCCESSFULLY } from '../../demand-generation/demand-generation.actions'
import { transformRows, transformTreeData } from '@/utils/calendar';
import { AnalyticsTypes, IGetDemandsChartDataParams } from '@/app/@core/entity/demand.service';

export const PAGE__DEMAND_PLANNING_STATE_KEY = 'page_demandPlanning';

/*************************************
 * State
 *************************************/

export interface IDemandPlanningPageState {
  datesSubfiltering?: DataZoomEvent;
  groupings: ReadonlyArray<string>;
  kpiTrackerGroupings: ReadonlyArray<string>;
  datasetYearGroupings: Array<string>;
  kpiTrackerDatasetYearGroupings: Array<string>;
  analyticGroupings: Array<string>;
  kpiTrackerAnalyticGroupings: Array<string>;
  dateAggregation: DateAggregationOption;
  demands: IDemandChartResponse;
  demandLoadedParams?: IGetDemandsChartDataParams | {};
  demandsActualX: IDemandActualXResponse;
  demands_loading: boolean;
  demandsActualXLoadingCount: number;
  demands_error?: any;
  demandsActualX_error?: any;
  impacts: IDemandChartResponse;
  impacts_loading: boolean;
  impacts_error?: any;
  uom?: string;
  numericScale: string;
  decimalPlaces: number;
  demandsByProxy: IReducedHistoricalDemandByProxy[];
  demandsByProxyLoading: boolean,
  demandsByProxyError: any;
  listCurrencies: Currency[];
  listCurrencies_loading: boolean;
  listCurrencies_error?: any;
}

export const initialState: IDemandPlanningPageState = {
  dateAggregation: DateAggregationOption.MONTH,
  groupings: ['channel', 'subCategory', 'brands'],
  kpiTrackerGroupings: ['channel', 'subCategory', 'brands'],
  datasetYearGroupings: ['Dataset', 'Year'],
  kpiTrackerDatasetYearGroupings: ['Dataset', 'Year'],
  analyticGroupings: [AnalyticsTypes.FY_TOTAL, AnalyticsTypes.QUARTER_TOTAL, AnalyticsTypes.HALF_YEAR_TOTAL, AnalyticsTypes.HALF_YEAR_AVERAGE],
  kpiTrackerAnalyticGroupings: [AnalyticsTypes.FY_TOTAL, AnalyticsTypes.QUARTER_TOTAL, AnalyticsTypes.HALF_YEAR_TOTAL, AnalyticsTypes.HALF_YEAR_AVERAGE],
  demands: DEFAULT_DEMAND_CHART_RESPONSE,
  demandLoadedParams: {},
  demandsActualX: DEFAULT_DEMAND_ACTUAL_X_RESPONSE,
  impacts: DEFAULT_DEMAND_CHART_RESPONSE,
  demands_loading: false,
  demandsActualXLoadingCount: 0,
  impacts_loading: false,
  // Set default value for uom
  uom: '',
  numericScale: 'D',
  decimalPlaces: 0,
  demandsByProxy: [],
  demandsByProxyLoading: false,
  demandsByProxyError: undefined,
  listCurrencies: [],
  listCurrencies_loading: false,
};

/*************************************
 * Reducers
 *************************************/

export const page_demandPLanningReducer = createReducer(
  initialState,
  on(
    UPDATE_DATE_ZOOM,
    (state, { zoom }): IDemandPlanningPageState => ({ ...state, datesSubfiltering: zoom })
  ),
  on(
    UPDATE_DATE_AGGREGATION,
    (state, { dateAggregation }): IDemandPlanningPageState => ({
      ...state,
      dateAggregation
    })
  ),
  on(
    UPDATE_UOM,
    (state, { uom }): IDemandPlanningPageState => ({
      ...state,
      uom
    }),
  ),
  on(
    LOAD_DEMAND_CHART_DATA,
    (state): IDemandPlanningPageState => ({
      ...state,
      demands_loading: true,
      demandsActualXLoadingCount: state.demandsActualXLoadingCount + 1,
      demands_error: undefined
    })
  ),
  on(
    LOAD_DEMAND_CHART_DATA_SUCCESS,
    (state, { data }): IDemandPlanningPageState => ({
      ...state,
      demands: data,
      demands_loading: false,
      demands_error: undefined
    })
  ),
  on(
    LOAD_DEMAND_CHART_DATA_FAILED,
    (state, { error }): IDemandPlanningPageState => ({
      ...state,
      demands: DEFAULT_DEMAND_CHART_RESPONSE,
      demands_loading: false,
      demands_error: error
    })
  ),

  on(
    LOAD_DEMAND_ACTUAL_X_DATA,
    (state): IDemandPlanningPageState => ({
      ...state,
      demandsActualXLoadingCount: state.demandsActualXLoadingCount + 1,
      demandsActualX_error: undefined
    })
  ),
  on(
    LOAD_DEMAND_ACTUAL_X_DATA_SUCCESS,
    (state, { data }): IDemandPlanningPageState => {
      // The objective of this update is to modify or introduce new rows, columns, or trees, utilizing keys such as 'actual-1', 'actual-2', etc., as the basis for the alterations.
      const columns = updateOrAddRow([...state.demandsActualX.columns], data.columns[0][0], data.columns);
      const rows = updateOrAddRow([...state.demandsActualX.rows], data.rows[0][0], data.rows);
      const tree = updateOrAddTreeElement([...state.demandsActualX.tree], data.tree[0].key, data.tree[0]);

      // The goal of this transformation is to accurately offset the row data, aligning it with the appropriate indices correlating to the columns in raw_demands.
      const transformedActualXRows = transformRows(rows, columns, state.demands.columns);
      const transformedActualXTree = transformTreeData(tree, columns, state.demands.columns);
      
      return ({
        ...state,
        demandsActualX: {
          columns,
          rows: transformedActualXRows,
          tree: transformedActualXTree
        },
        demandsActualXLoadingCount: Math.max(0, state.demandsActualXLoadingCount - 1), // Decrement counter safely
        demandsActualX_error: undefined
      })
    }
  ),
  on(
    UNLOAD_DEMAND_ACTUAL_X_DATA,
    (state, { key }): IDemandPlanningPageState => ({
      ...state,
      demandsActualX: {
        columns: removeRow([...state.demandsActualX.columns], key),
        rows: removeRow([...state.demandsActualX.rows], key),
        tree: removeTreeElement([...state.demandsActualX.tree], key)
      },
      demandsActualXLoadingCount: Math.max(0, state.demandsActualXLoadingCount - 1), // Decrement counter safely
      demandsActualX_error: undefined
    })
  ),
  on(
    LOAD_DEMAND_ACTUAL_X_DATA_FAILED,
    (state, { error }): IDemandPlanningPageState => ({
      ...state,
      demandsActualX: DEFAULT_DEMAND_ACTUAL_X_RESPONSE,
      demandsActualXLoadingCount: Math.max(0, state.demandsActualXLoadingCount - 1), // Decrement counter safely
      demandsActualX_error: error
    })
  ),

  on(
    LOAD_DEMAND_MULTI_ACTUAL_X_DATA,
    (state): IDemandPlanningPageState => ({
      ...state,
      demandsActualXLoadingCount: state.demandsActualXLoadingCount + 1,
      demandsActualX_error: undefined
    })
  ),
  on(
    LOAD_DEMAND_MULTI_ACTUAL_X_DATA_SUCCESS,
    (state, { data }): IDemandPlanningPageState => {
      // The goal of this transformation is to accurately offset the row data, aligning it with the appropriate indices correlating to the columns in raw_demands.
      const transformedActualXRows = transformRows(data.rows, data.columns, state.demands.columns);
      const transformedActualXTree = transformTreeData(data.tree, data.columns, state.demands.columns);
      
      return ({
        ...state,
        demandsActualX: {
          ...data,
          rows: transformedActualXRows,
          tree: transformedActualXTree
        },
        demandsActualXLoadingCount: Math.max(0, state.demandsActualXLoadingCount - 1), // Decrement counter safely
        demandsActualX_error: undefined
      })
    }
  ),
  on(
    LOAD_DEMAND_MULTI_ACTUAL_X_DATA_FAILED,
    (state, { error }): IDemandPlanningPageState => ({
      ...state,
      demandsActualX: DEFAULT_DEMAND_ACTUAL_X_RESPONSE,
      demandsActualXLoadingCount: Math.max(0, state.demandsActualXLoadingCount - 1), // Decrement counter safely
      demandsActualX_error: error
    })
  ),

  on(
    LOAD_DEMAND_BY_PROXY_DATA,
    (state): IDemandPlanningPageState => ({
      ...state,
      demandsByProxyLoading: true,
      demandsByProxyError: undefined
    })
  ),
  on(
    LOAD_DEMAND_BY_PROXY_DATA_SUCCESS,
    (state, { data }): IDemandPlanningPageState => ({
      ...state,
      demandsByProxy: data,
      demandsByProxyLoading: false,
      demandsByProxyError: undefined
    })
  ),
  on(
    LOAD_DEMAND_BY_PROXY_DATA_FAILED,
    (state, { error }): IDemandPlanningPageState => ({
      ...state,
      demandsByProxy: [],
      demandsByProxyLoading: false,
      demandsByProxyError: error
    })
  ),

  on(
    LOAD_DEMAND_IMPACTS_CHART_DATA,
    (state): IDemandPlanningPageState => ({
      ...state,
      impacts_loading: true,
      impacts_error: undefined
    })
  ),
  on(
    LOAD_DEMAND_IMPACTS_CHART_DATA_SUCCESS,
    (state, { data }): IDemandPlanningPageState => ({
      ...state,
      impacts: data,
      impacts_loading: false,
      impacts_error: undefined
    })
  ),
  on(
    LOAD_DEMAND_IMPACTS_CHART_DATA_FAILED,
    (state, { error }): IDemandPlanningPageState => ({
      ...state,
      impacts: DEFAULT_DEMAND_CHART_RESPONSE,
      impacts_loading: false,
      impacts_error: error
    })
  ),

  on(
    START_CHECKING_PENDING_DEMANDS_SHAPER_TASKS,
    (state): IDemandPlanningPageState => ({
      ...state,
      impacts_loading: true,
      impacts_error: undefined
    })
  ),

  on(
    DEMANDS_SHAPER_TASKS_RAN_SUCCESSFULLY,
    (state): IDemandPlanningPageState => ({
      ...state,
      impacts_loading: false,
      impacts_error: undefined
    })
  ),

  on(
    UPDATE_DEMAND_GROUPING,
    (state, { data }): IDemandPlanningPageState => ({ ...state, groupings: data })
  ),

  on(
    UPDATE_KPI_TRACKER_GROUPING,
    (state, { data }): IDemandPlanningPageState => ({ ...state, kpiTrackerGroupings: data })
  ),
  
  on(
    UPDATE_DEMAND_DATASET_YEAR_GROUPING,
    (state, { data }): IDemandPlanningPageState => ({ ...state, datasetYearGroupings: data })
  ),

  on(
    UPDATE_KPI_TRACKER_DATASET_YEAR_GROUPING,
    (state, { data }): IDemandPlanningPageState => ({ ...state, kpiTrackerDatasetYearGroupings: data })
  ),
  
  on(
    UPDATE_DEMAND_ANALYTIC_GROUPING,
    (state, { data }): IDemandPlanningPageState => ({ ...state, analyticGroupings: data })
  ),

  on(
    UPDATE_KPI_TRACKER_ANALYTIC_GROUPING,
    (state, { data }): IDemandPlanningPageState => ({ ...state, kpiTrackerAnalyticGroupings: data })
  ),
  on(
    SET_DECIMAL_PLACES,
    (state, { decimalPlaces }): IDemandPlanningPageState => ({
      ...state,
      decimalPlaces,
    })
  ),
  on(
    SET_NUMERIC_SCALE,
    (state, { numericScale }): IDemandPlanningPageState => ({
      ...state,
      numericScale,
    })
  ),
  on(
    UPDATE_DEMAND_LOADED_PARAMS,
    (state, { params }): IDemandPlanningPageState => ({
        ...state,
        demandLoadedParams: params
      }
    )
  ),
  on(
    LOAD_LIST_CURRENCIES,
    (state): IDemandPlanningPageState => ({
      ...state,
      listCurrencies_loading: true,
      listCurrencies_error: undefined,
    }),
  ),
  on(
    LOAD_LIST_CURRENCIES_SUCCESS,
    (state, { data }): IDemandPlanningPageState => ({
      ...state,
      listCurrencies: data,
      listCurrencies_loading: false,
      listCurrencies_error: undefined,
    }),
  ),
  on(
    LOAD_LIST_CURRENCIES_FAILED,
    (state, { error }): IDemandPlanningPageState => ({
      ...state,
      listCurrencies_loading: false,
      listCurrencies_error: error,
    }),
  ),
);
