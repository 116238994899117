import { EChartsOption, LineSeriesOption } from 'echarts';
import { pick, sum } from 'rambda';
import { format, parseISO, isValid } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';

import { Scenario } from '@/app/@core/interfaces/business/scenario';
import {
  IDemandChartResponse,
  IDemandTreeNode,
  IRunRates,
} from '@/store/pages/demand-planning/demand-planning.actions';
import { notNullOrUndefined } from '../../../utils/notNullOrUndefined';
import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { sumIfHaveValue } from '../../../utils/numbers';
import { ATOMIC_KPIS_COLUMN_LOOKUP, PROP_TO_COLUMN_LOOKUP } from '@/app/@core/constants/entity-matcher.constants';
import { DemandTableData, DemandTableRow } from './demand-planning.selectors';
import { AnalyticsGroupMappings } from '@/app/@core/entity/demand.service';
import { Workspace } from '@/app/@core/interfaces/common/workspace';

export const DEFAULT_ECHARTS_SERIES_OPTIONS = <LineSeriesOption>{
  type: 'line',
  // smooth: true,
  showSymbol: false,
  legendHoverLink: false,
  selectedMode: 'series',
  lineStyle: { width: 1 },
};

// mainType?: 'axisPointer';
// type?: 'line' | 'shadow' | 'cross' | 'none';
// link?: AxisPointerLink[];
/** Override configs for timeseries chart. */
export const DEFAULT_ECHARTS_OPTIONS: EChartsOption = {
  title: {
    text: 'Demand chart',
    textStyle: { fontWeight: 500, fontFamily: 'Roboto', fontSize: 14, color: '#00355C' },
  },
  grid: { bottom: '40', containLabel: true, left: 200, right: 20 },
  legend: { icon: 'roundRect', itemHeight: 2, left: 'left', orient: 'vertical', top: 50 },
  tooltip: {
    trigger: 'axis'
  },
  xAxis: { type: 'category' },
  yAxis: {
  },
  dataZoom: [
    {
      type: 'slider',
      xAxisIndex: 0,
      filterMode: 'none',
      bottom: 5
    },
    {
      type: 'slider',
      yAxisIndex: 0,
      filterMode: 'none'
    }
  ]
};

export const ANALYTICS_TYPES = [
  'Q1', 'Q2', 'Q3', 'Q4',
  'Q1 Avg', 'Q2 Avg', 'Q3 Avg', 'Q4 Avg',
  'H1', 'H2', 'H1 Avg', 'H2 Avg',
  'Y', 'Y Avg',
  'YTD', 'YTG', 'Var', 'Var%',
  'Avg YTD', 'Avg YTG', 'Avg Var', 'Avg Var%'
];

export const PERCENT_KPIS = [
  'serviceLevel',
  'margin',
  'COGSPercent',
  'GrossMargin',
  'DPMPercent',
  'DPMAPercent',
  'EBITPercent',
  'LocalCaseFillRate',
  'FIECaseFillRate',
];

export const MONTH_END_KPIS = [
  'UnconstrainedSIT',
  'ConstrainedSIT',
  'UnconstrainedCLS',
  'ConstrainedCLS',
  'UnconstrainedAverageSalesOutGSV',
  'ConstrainedAverageSalesOutGSV',
  'UnconstrainedCLSGSV',
  'ConstrainedCLSGSV',
  'UnconstrainedCLSGSVValuation',
  'ConstrainedCLSGSVValuation',
  'UnconstrainedValuationSIT',
  'ConstrainedValuationSIT',
];

// Define the order of types by UI
export const analyticsTypesOrderCreator = (analyticGroupings: string[]): string[] => 
  analyticGroupings.map(analytic => [...(AnalyticsGroupMappings[analytic] || [])]).flat();

export const TYPE_REGEX_MAP = {
  "Q": /^Q\d$/,
  "Q Avg": /^Q\d Avg$/,
  "H": /^H\d$/,
  "H Avg": /^H\d Avg$/,
  "Y": /^Y$/,
  "Y Avg": /^Y Avg$/,
  "YTD": /^YTD$/,
  "Avg YTD": /^Avg YTD$/,
  "YTG": /^YTG$/,
  "Var": /^Var$/,
  "Var%": /^Var%$/,
  "Avg YTG": /^Avg YTG$/,
  "Avg Var": /^Avg Var$/,
  "Avg Var%": /^Avg Var%$/,
};

export interface DataIndexByYear {
  [year: string]: number[];
}

export interface Y2YData {
  columns: string[];
  years: string[];
  fYears: string[];
  dataIndexByYear: DataIndexByYear;
}
export const MONTH_ABBREVIATIONS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const DEFAULT_Y2Y_COLUMNS = (months: string[]) => ["Year", ...months];
export const RUN_RATE_PERIODS = ['P12M', 'P9M', 'P6M', 'P3M'] as const;

export const reorderMonths = (months: string[], fiscalYearStartMonth: number = 1): string[] => {
  const startMonthIndex = fiscalYearStartMonth - 1;
  return [...months.slice(startMonthIndex), ...months.slice(0, startMonthIndex)];
};

export function getMonthNumber(monthAbbreviation: string): string {
  const index = MONTH_ABBREVIATIONS.indexOf(monthAbbreviation);
  if (index === -1) {
    return '';
  }
  return String(index + 1).padStart(2, '0');
}

export function computeTotalLookupByNames(
  combinedDemandsChartData: IDemandChartResponse,
  scenarios: { id: string, name: string }[],
) {
  return Object.fromEntries(
    scenarios.map((s) => {
      const data = takeRowDataById(s.id, combinedDemandsChartData.rows) || [];
      return [s.name, sum(data.filter(notNullOrUndefined))];
    }),
  );
}
export function calculateDataArea(rawData: { rows: Array<any>; columns: string[] }) {
  enum D {
    actual = 'actual',
    current = 'current',
    future = 'future',
  }
  // Area of Future is determinded by forecast base's data
  const data: Record<D, any[]> = {
    actual: rawData.rows.filter((r) => r[0] === 'actual').flatMap((r) => r[1]),
    current: rawData.rows.filter((r) => r[0] === 'current').flatMap((r) => r[1]),
    // Get the Forecast Base data, or if not found take the last row, after excluxing all special rows
    future: rawData.rows
      .filter((r) => r[0] !== 'actual')
      .filter((r) => r[0] !== 'current')
      .filter((r) => r[0] !== 'committed')
      .filter((r, i) => r[0] === 'forecast' || i === rawData.rows.length - 1)
      .flatMap((r) => r[1]),
  };
  const dataIndies: Record<D, number[]> = {
    actual: findIndexRangeOfAvailableData(data.actual),
    current: findIndexRangeOfAvailableData(data.current),
    future: findIndexRangeOfAvailableData(data.future),
  };
  const colValues: Record<D, string[]> = {
    actual: dataIndies.actual.map((i) => rawData.columns[i]),
    current: dataIndies.current.map((i) => rawData.columns[i]),
    future: dataIndies.future.map((i) => rawData.columns[i]),
  };
  const labelColors: Record<D, string> = {
    actual: '#979797',
    current: '#AD3781',
    future: '#222B45',
  };
  const bgColors: Record<D, string> = {
    actual: '#E3E3E340',
    current: '#7C596F40',
    future: 'transparent',
  };

  const result: Array<[string, LineSeriesOption['markArea']]> = Object.values(D).map((val) => [
    val,
    dataIndies[val].length
      ? {
        silent: true,
        data: [
          [
            {
              name: val.toUpperCase(),
              label: { color: labelColors[val] },
              itemStyle: { color: bgColors[val] },
              xAxis: colValues[val][0],
            },
            { xAxis: colValues[val][1] },
          ],
        ],
      }
      : undefined,
  ]);

  return Object.fromEntries(result);
}
export function combineDemandTree(
  tree1?: IDemandTreeNode[],
  tree2?: IDemandTreeNode[],
  dateColumns?: string[],
  getCurrencyRate?: (date: string) => number | undefined,
): IDemandTreeNode[] | undefined {
  if (!tree1 || !tree2) return tree1 || tree2;

  const keys = [...new Set([...tree1.map(({ key }) => key), ...tree2.map(({ key }) => key)])];

  return keys
    .map((k) => {
      let node1 = tree1.find(({ key }) => key === k);
      let node2 = tree2.find(({ key }) => key === k);

      if (!node1 || !node2) {
        return applyCurrencyRateToNode(node1, getCurrencyRate, dateColumns) ||
              applyCurrencyRateToNode(node2, getCurrencyRate, dateColumns);
      }

      return <IDemandTreeNode>{
        key: k,
        label: node1.label,
        data: node1.data.map((val, i) => {
          const rate = getCurrencyRate && getCurrencyRate(dateColumns ? dateColumns[i] : '') || 1;
          const sumValue = sumIfHaveValue(val, node2?.data[i]);
          return sumValue != null ? rate * sumValue : sumValue;
        }),
        children: combineDemandTree(node1.children, node2.children, dateColumns, getCurrencyRate),
      };
    })
    .filter(notNullOrUndefined);
}

export function formatFiscalYear(date: Date, fiscalYearStartMonth: number): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;  // getMonth() is zero-based

  let fiscalYearStart: number;
  let fiscalYearEnd: number;

  if (month < fiscalYearStartMonth) {
      // Fiscal year starts in the previous year
      fiscalYearStart = year - 1;
      fiscalYearEnd = year;
  } else {
      // Fiscal year starts in the current year
      fiscalYearStart = year;
      fiscalYearEnd = year + 1;
  }

  if (fiscalYearStartMonth === 1) {
      // If fiscal year starts in January, use only one year
      return `FY${fiscalYearStart.toString().slice(-2)}`;
  } else {
      // Otherwise, use both years
      return `FY${fiscalYearStart.toString().slice(-2)}/${fiscalYearEnd.toString().slice(-2)}`;
  }
}

export function formatByDateAggregationGen(dao: DateAggregationOption, fiscalYearStartMonth:number = 1) {
  return (s?: string) => {
    if (!s) return '';
    const parsed = parseISO(s);
    if (!isValid(parsed)) return s;
    switch (dao) {
      default:
      case DateAggregationOption.DAY:
        return format(parsed, 'dd MMM yy');
      case DateAggregationOption.WEEK:
        return format(new Date(s), "'W'ww MMM yyyy", { weekStartsOn: 0, firstWeekContainsDate: 6 });
      case DateAggregationOption.MONTH:
        return format(parsed, 'MMM yy');
      case DateAggregationOption.FISCAL_YEAR:
        return formatFiscalYear(parsed, fiscalYearStartMonth);
    }
  };
}
// generateShorterKpiName(kpiName: string): string {
//   if (kpiName.concat('\n')) {
//     kpiName = kpiName.replace(/\n/g, ' ');
//   }
//   return kpiName.match(/.{1,20}/g)?.[0] + '...';
// }
export function generateShortedScenarioLegend(scenarioName: string): string {
  if (scenarioName.concat('\n')) {
    scenarioName = scenarioName.replace(/\n/g, ' ');
  }

  if (scenarioName.length > 20) {
    return scenarioName.match(/.{1,20}/g)?.[0] + '...';
  }

  return scenarioName;
}
export function takeRowDataById<T extends number | null | undefined>(
  dataId: string,
  rows: Array<[id: string, data: Array<T>]>,
) {
  return rows
    .filter(([id]) => id === dataId)
    .map(([_, data]) => data)
    .pop();
}

export function takeTreeNodeChildrenById(id: string, treeNodes: IDemandTreeNode[]) {
  return treeNodes
    .filter(({ key }) => key === id)
    .map(({ children }) => children)
    .filter(notNullOrUndefined)
    .pop();
}

/** Given a list of entity matcher properties, return their equivalent column names. */
export function propsToColumns(props: readonly string[], isAtomicKpis: boolean = false): string[] {
  return props.map((prop) => isAtomicKpis ? ATOMIC_KPIS_COLUMN_LOOKUP[prop] : PROP_TO_COLUMN_LOOKUP[prop]);
}

function findIndexRangeOfAvailableData<T>(array: Array<T | null | undefined>) {
  let first: number | null = null;
  let last: number | null = null;

  for (let index = 0; index < array.length; index++) {
    const item = array[index];
    if (item === null || item === undefined) continue;
    if (first === null) first = index;
    last = index;
  }

  if (first !== null && last !== null) return [first, last];
  return [];
}

export function splitAnalyticTitle(title: string): [number, string] {
  const year = parseInt(title?.substring(0, 4));
  const type = title?.substring(4).trim();

  return [year, type]
}

export function checkAnalyticType(str: string, type): boolean {
  const analyticKey = splitAnalyticTitle(str)[1];
  const regexPattern = TYPE_REGEX_MAP[type];

  return regexPattern ? regexPattern.test(analyticKey) : false;
}

export function updateOrAddRow(rows, key, newRow) {
  // Find the index of the row with the specified key.
  const existingRowIndex = rows.findIndex(row => row[0] === key);

  if (existingRowIndex !== -1) {
    // Replace the existing row if it's found.
    rows[existingRowIndex][0] = newRow[0][0];
  } else {
    // Add the new row if no existing row was found.
    rows.push(newRow[0]);
  }

  return rows;
}

export function removeRow(rows, key) {
  const updatedRows = rows.filter(row => row[0] !== key);

  return updatedRows;
}

export function updateOrAddTreeElement(tree, key, newElement) {
  const elementExists = tree.some(el => el.key === key);

  if (elementExists) {
    // Map to replace the element with a new object.
    return tree.map(el => el.key === key ? newElement : el);
  } else {
    // Concat to add the new element.
    return tree.concat(newElement);
  }
}

export function removeTreeElement(tree, key) {
  return tree.filter(el => el.key !== key);
}

export function removeDuplicates(scenarios) {
  const uniqueIdMap = new Map();

  return scenarios.filter((scenario) => {
    if (!uniqueIdMap.has(scenario.id)) {
      uniqueIdMap.set(scenario.id, true);
      return true;
    }
    return false;
  });
};

export function filterSpecialColumns(columns: string[], analyticGroupings: string[]) {
  const analyticsTypesOrder = analyticsTypesOrderCreator(analyticGroupings);

  const uniqueFilteredColumns: Set<string> = new Set();

  columns.forEach(column => {
    const [, type] = splitAnalyticTitle(column);
    const foundTypes = ANALYTICS_TYPES.find(analyticsTypes => analyticsTypes.includes(type));
    if (foundTypes) {
      uniqueFilteredColumns.add(foundTypes);
    }
  });

  return Array.from(uniqueFilteredColumns).sort((a: string, b: string) => {
    return analyticsTypesOrder.indexOf(a) - analyticsTypesOrder.indexOf(b);
  });
}

export function extractYears(columns: string[]): string[] {
  const years: Set<string> = new Set();
  columns.forEach(date => {
    const year = date.split('-')[0];
    if (year.length === 4) {
      years.add(year);
    }
  });
  return Array.from(years);
}

export function getColumnPosition(column: string, perYearColumns: string[]) {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/; // Pattern for dates like "2024-03-01"
  let type = "";
  if (datePattern.test(column)) {
    // It's a date, convert month to abbreviation
    const monthIndex = parseInt(column.split('-')[1], 10) - 1; // Convert to 0-based index
    type = MONTH_ABBREVIATIONS[monthIndex];
  } else {
    const [, analyticalType] = splitAnalyticTitle(column);
    type = analyticalType;
  }
  return perYearColumns.indexOf(type);
};

export function getAdjustedGroupedYear(column: string, fiscalYearStartMonth: number = 1) {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/; // Pattern for dates like "2024-03-01"
  const [year] = splitAnalyticTitle(column);
  let adjustedYear = year;
  if (datePattern.test(column)) {
    const month = parseInt(column.substring(5, 7), 10);
    // Adjust the year based on the fiscal year start month
    adjustedYear = month >= fiscalYearStartMonth ? year : year - 1;
  }

  return adjustedYear;
}

export function groupByYear(columns: string[], perYearColumns: string[], fiscalYearStartMonth: number = 1) {
  return columns.reduce((acc, column, index) => {
    const adjustedYear = getAdjustedGroupedYear(column, fiscalYearStartMonth);
    if (!acc[adjustedYear]) {
      acc[adjustedYear] = Array(perYearColumns.length).fill(null);
    }
    const position = getColumnPosition(column, perYearColumns);
    if (position !== -1) { // If the type is found in perYearColumns
      acc[adjustedYear][position] = index;
    }
    return acc;
  }, {});
}

export function getY2YData(treeDataColumns: string[], analyticGroupings: string[], fiscalYearStartMonth: number = 1): Y2YData {
  const specialColumns = filterSpecialColumns(treeDataColumns, analyticGroupings);
  const reorderedMonths = reorderMonths(MONTH_ABBREVIATIONS, fiscalYearStartMonth);
  const columns = [...DEFAULT_Y2Y_COLUMNS(reorderedMonths), ...specialColumns];
  const dataIndexByYear = groupByYear(treeDataColumns, columns.slice(1), fiscalYearStartMonth);
  const years = Object.keys(dataIndexByYear);
  const fYears = years.map(y => formatFiscalYear(new Date(`${y}-${fiscalYearStartMonth}-01`), fiscalYearStartMonth));

  return { columns, years, fYears, dataIndexByYear };
}

export function getDataIndex(y2yData: any, year: string, columnIndex: number) {
  return y2yData?.dataIndexByYear[year][columnIndex];
}

export function getDataValue(row: any, y2yData, year: string, columnIndex: number) {
  const dataIndex = getDataIndex(y2yData, year, columnIndex);
  if (dataIndex == null) {
    return null;
  }
  return row?.data[dataIndex];
}

export function calculateY2YDiff(row: DemandTableRow, y2yData: Y2YData) {
  const { years, dataIndexByYear } = y2yData;
  const y2yDiffByYear: DataIndexByYear = {};
  const y2yDiffPercentByYear: DataIndexByYear = {};

  for (let [i, year] of years.entries()) {
    if (i === 0) {
      // For the first year in the array, there's no previous year to compare to
      y2yDiffByYear[year] = dataIndexByYear[year].map(d => d != null ? 0 : d);
      y2yDiffPercentByYear[year] = dataIndexByYear[year].map(d => d != null ? 0 : d);
    } else {
      const previousYear = years[i - 1];
      y2yDiffByYear[year] = dataIndexByYear[year].map((d: number, columnIndex: number) => {
        if (d == null) {
          return d;
        }

        const previousYearValue = getDataValue(row, y2yData, previousYear, columnIndex);
        const currentYearValue = getDataValue(row, y2yData, year, columnIndex);
        const diff = currentYearValue - previousYearValue;
        return diff;
      });

      y2yDiffPercentByYear[year] = dataIndexByYear[year].map((d: number, columnIndex: number) => {
        if (d == null) {
          return d;
        }

        const previousYearValue = getDataValue(row, y2yData, previousYear, columnIndex);
        const currentYearValue = getDataValue(row, y2yData, year, columnIndex);
        const diffAbs = (previousYearValue != null && previousYearValue !== 0) ? (currentYearValue / previousYearValue) - 1 : 0;
        return diffAbs;
      });
    }
  }
  row.y2yDiff = y2yDiffByYear;
  row.y2yDiffPercent = y2yDiffPercentByYear;

  // Recursively process each child
  row.children.forEach(child => {
    calculateY2YDiff(child, y2yData);
  });
}

export function calculateAverages(data: (number | null)[]): IRunRates {
    // Function to calculate the average of the last 'n' elements, or null if not enough elements
    const averageLastN = (data, n) => {
        return data.length >= n 
            ? data.slice(-n).reduce((acc, val) => acc + val, 0) / n
            : undefined;
    };

    // Dynamic calculation of averages for the specified periods
    const averages: IRunRates = {};
    RUN_RATE_PERIODS.forEach(period => {
        const periodLength = parseInt(period.substring(1), 10);
        averages[period] = averageLastN(data, periodLength);
    });

    return averages;
}


export function calculateTrendLine(id, xData, yData) {
  const n = xData.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumX2 = 0;

  let excludedNumberOfMonth = 0;

  for (let i = 0; i < n; i++) {
    if (yData[i] == null) {
      excludedNumberOfMonth += 1;
    } else {
      sumX += i + 1;
      sumY += yData[i];

      sumXY += (i + 1) * yData[i];
      sumX2 += (i + 1) ** 2;
    }
  }

  let newN = n - excludedNumberOfMonth > 0 ? n - excludedNumberOfMonth : 1

  const slope = (newN * sumXY - sumX * sumY) / (newN * sumX2 - sumX ** 2);
  const intercept = (sumY - slope * sumX) / newN;

  const trendLineData = xData.map((x, index) => {
    return [x, yData[index] != null ? slope * (index + 1) + intercept : null]
  });
  return trendLineData;
};

export interface Currency {
  _id: string;
  workspace_id: string;
  from: string;
  to: string;
  rate: number;
  date: string;
}

export function currencyConversionLookup(rows: Currency[]) {
  if (rows.length < 1) {
    // no rate change
    return () => 1;
  }
  const table = new Map<string, number>();
  const lookup = (date: string) => {
    const d = new Date(date);
    const first = rows[0];
    if (d.getTime() <= new Date(first.date).getTime()) {
      return first.rate;
    }
    for (let i = 1; i < rows.length; i++) {
      const prev = rows[i - 1];
      const curr = rows[i];
      // prev < input date < curr
      if (new Date(prev.date).getTime() <= d.getTime() && d.getTime() < new Date(curr.date).getTime()) {
        return prev.rate;
      }
    }
    // return the last 
    return rows[rows.length - 1].rate;
  }

  return (date: string) => {
    if (!table.has(date)) {
      table.set(date, lookup(date));
    }
    return table.get(date);
  }
}

export function applyCurrencyRateToData(
  data: Array<number | null>,
  getCurrencyRate?: (date: string) => number | undefined,
  dateColumns?: string[]
) {
  return data.map((val, i) => {
      const rate = getCurrencyRate && getCurrencyRate(dateColumns ? dateColumns[i] : '') || 1;
      return val != null ? rate * val : val;
  });
}

export function applyCurrencyRateToNode(
  node?: IDemandTreeNode,
  getCurrencyRate?: (date: string) => number | undefined,
  dateColumns?: string[]
) {
  if (!node) return node;
  
  const data = applyCurrencyRateToData(node.data, getCurrencyRate, dateColumns);
  const children = node.children ? node.children.map(child => applyCurrencyRateToNode(child, getCurrencyRate, dateColumns)) : null;
  return {
      ...node,
      data,
      children,
  };
}

export function filterMasterDataFields(fields: string[], workspace: Workspace | undefined) {
  const hiddenMasterDataFieldNames = workspace?.settings?.hiddenMasterDataFieldNames || [];
  return fields.filter(field => !hiddenMasterDataFieldNames.includes(field));
}

export function findFirstValidNullIndex(array: (number | null)[]): number {
  let lastNonNullIndex = -1;

  // Find the index of the last non-null element
  for (let i = 0; i < array.length; i++) {
    if (array[i] != null) {
      lastNonNullIndex = i;
    }
  }

  // Check if the element immediately after the last non-null element is null
  if (lastNonNullIndex !== -1 && lastNonNullIndex + 1 < array.length && array[lastNonNullIndex + 1] == null) {
    return lastNonNullIndex + 1;
  }

  // Return -1 if no such element is found
  return -1;
};

export function formatFiscalYearForColumnTitles(columns: string[], fiscalYearStartMonth: number = 1) {
  return columns.map((c) => {
    const isAnalyticsType = ANALYTICS_TYPES.some(q => c.endsWith(q))
    if (isAnalyticsType) {
      const [year, type] = splitAnalyticTitle(c);
      const fiscalYear = formatFiscalYear(new Date(`${year}-${fiscalYearStartMonth}-01`), fiscalYearStartMonth);
      return `${fiscalYear} ${type}`;
    }
    return c;
  });
}

export function enhanceTableWithAnalytics(
  table: DemandTableData,
  analyticGroupings: string[],
  fiscalYearStartMonth: number,
  highlightedScenario: Scenario | undefined,
  analyticColumnParams: any,
  isFiscalYear: boolean = false,
) {
  table.addAnalyticColumns(analyticColumnParams, isFiscalYear);
  // Should calculate y2yData after addAnalyticColumns() to get full columns with analytics
  table.y2yData = getY2YData(table.columns, analyticGroupings, fiscalYearStartMonth);

  if (highlightedScenario) {
    const key = highlightedScenario.id;
    const highlightedRow = table.tree.find(t => t.key === key);
    if (highlightedRow) {
      table.compare(highlightedRow);
    }
  }

  table.tree.forEach(row => {
    // calculate y2y comparison for all table parent rows
    // pass row by reference, therefore mutate the table's row directly
    calculateY2YDiff(row, table.y2yData!);
  });
}

export function getFiscalYear(dateStr: string, fiscalYearStartMonth: number): string {
  const parsedDate = new Date(dateStr);
  const month = parsedDate.getMonth() + 1;
  const year = parsedDate.getFullYear();
  const fiscalYear = month < fiscalYearStartMonth ? year - 1 : year;
  return `${fiscalYear}-${String(fiscalYearStartMonth).padStart(2, '0')}-01`;
}

export function isDateColumn(col: string): boolean {
  return /^\d{4}-\d{2}-\d{2}$/.test(col);
}

export function aggregateRows(
  rows: [string, (number | null)[]][],
  columns: string[],
  fiscalYearColumns: string[],
  fiscalYearStartMonth: number,
  actualValues: (number | null)[] | null
): [string, (number | null)[]][] {
  return rows.map((row): [string, (number | null)[]] => {
    if (!Array.isArray(row) || row.length < 2 || typeof row[0] !== 'string' || !Array.isArray(row[1])) {
      return ['', fiscalYearColumns.map(() => 0)];
    }

    const scenarioId = row[0];
    const values = [...row[1]];
    const fiscalYearSums: { [key: string]: number } = {};
    const fiscalYearHasData: { [key: string]: boolean } = {};

    fiscalYearColumns.forEach(fy => {
      fiscalYearSums[fy] = 0;
      fiscalYearHasData[fy] = false;
    });

    columns.forEach((col, index) => {
      if (isDateColumn(col)) {
        const fiscalYearLabel = getFiscalYear(col, fiscalYearStartMonth);
        let value = values[index];

        if (scenarioId !== 'actual' && value == null && actualValues && index < actualValues.length) {
          value = actualValues[index] ?? value;
        }

        if (value != null) {
          fiscalYearSums[fiscalYearLabel] += value;
          fiscalYearHasData[fiscalYearLabel] = true;
        }
      }
    });

    return [
      scenarioId,
      fiscalYearColumns.map(fy => (fiscalYearHasData[fy] ? fiscalYearSums[fy] : null)),
    ];
  });
}

export function aggregateNodeData(
  node: IDemandTreeNode,
  columnMap: { [key: number]: number },
  originalColumns: string[],
  targetColumnCount: number
): IDemandTreeNode {
  const copiedNode = cloneDeep(node);
  if (!copiedNode.data) {
    return {
      ...copiedNode,
      children: copiedNode.children?.map(child => aggregateNodeData(child, columnMap, originalColumns, targetColumnCount)),
    };
  }

  const newData = new Array(targetColumnCount).fill(0);
  const newDiff = new Array(targetColumnCount).fill(0);
  const newY2YDiff: { [year: string]: (number | null)[] } = {};
  const newY2YDiffPercent: { [year: string]: (number | null)[] } = {};

  if (copiedNode.y2yDiff) {
    Object.keys(copiedNode.y2yDiff).forEach(year => {
      newY2YDiff[year] = new Array(targetColumnCount).fill(null);
      newY2YDiffPercent[year] = new Array(targetColumnCount).fill(null);
    });
  }

  originalColumns.forEach((col, oldIndex) => {
    const newIndex = columnMap[oldIndex];
    if (newIndex !== undefined) {
      const value = copiedNode.data![oldIndex];
      const diffValue = copiedNode.diff?.[oldIndex];
      const isDate = isDateColumn(col);

      if (value != null) {
        newData[newIndex] += isDate ? value : 0;
        if (!isDate) newData[newIndex] = value;
      }
      if (diffValue != null) {
        newDiff[newIndex] += isDate ? diffValue : 0;
        if (!isDate) newDiff[newIndex] = diffValue;
      }

      if (copiedNode.y2yDiff) {
        Object.keys(copiedNode.y2yDiff).forEach(year => {
          const y2yValue = copiedNode.y2yDiff![year][oldIndex];
          const y2yPercentValue = copiedNode.y2yDiffPercent![year][oldIndex];
          if (y2yValue != null) {
            newY2YDiff[year][newIndex] += isDate ? y2yValue : 0;
            if (!isDate) newY2YDiff[year][newIndex] = y2yValue;
          }
          if (y2yPercentValue != null) {
            newY2YDiffPercent[year][newIndex] += isDate ? y2yPercentValue : 0;
            if (!isDate) newY2YDiffPercent[year][newIndex] = y2yPercentValue;
          }
        });
      }
    }
  });

  return {
    ...copiedNode,
    data: newData,
    diff: newDiff,
    y2yDiff: Object.keys(newY2YDiff).length > 0 ? newY2YDiff : undefined,
    y2yDiffPercent: Object.keys(newY2YDiffPercent).length > 0 ? newY2YDiffPercent : undefined,
    children: copiedNode.children?.map(child => aggregateNodeData(child, columnMap, originalColumns, targetColumnCount)),
  };
}

export function aggregateChartDataByFiscalYear(
  data: IDemandChartResponse,
  fiscalYearStartMonth: number
): IDemandChartResponse {
  const copiedData = cloneDeep(data);
  const { columns, rows } = copiedData;

  const fiscalYearColumns: string[] = [];
  const seenFiscalYears = new Set<string>();

  columns.forEach(col => {
    if (isDateColumn(col)) {
      const fiscalYearStart = getFiscalYear(col, fiscalYearStartMonth);
      if (!seenFiscalYears.has(fiscalYearStart)) {
        fiscalYearColumns.push(fiscalYearStart);
        seenFiscalYears.add(fiscalYearStart);
      }
    }
  });

  const actualRow = rows.find(row => row[0] === 'actual');
  const actualValues = actualRow ? [...actualRow[1]] : null;

  const aggregatedRows = aggregateRows(rows, columns, fiscalYearColumns, fiscalYearStartMonth, actualValues);

  return {
    ...copiedData,
    columns: fiscalYearColumns,
    rows: aggregatedRows,
  };
}
