import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, from } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, filter } from 'rxjs/operators';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NB_AUTH_OPTIONS, NbAuthService, NbAuthOAuth2JWTToken } from '@nebular/auth';

import { UserData } from '@/app/@core/interfaces/common/users';
import { UserStore } from '@/app/@core/stores/user.store';
import { SettingsService } from '@/app/@core/backend/common/services/settings.service';
import { AbilityService } from '@/app/services/ability.service';
import { notNullOrUndefined } from '@/utils/notNullOrUndefined';
import * as AuthActions from './auth.actions';
import { select_authUser } from './auth.selectors';
import { DO_LOGOUT } from '../pages/layout/layout.actions';
import { SHOULD_UPDATE_USER_SETTINGS } from '../composed-actions';
import {
  select_decimalPlaces,
  select_demandAnalyticGroupings,
  select_demandDatasetYearGroupings,
  select_demandGroupings,
  select_kpiTrackerAnalyticGroupings,
  select_kpiTrackerDatasetYearGroupings,
  select_kpiTrackerGroupings,
  select_numericScale,
} from '../pages/demand-planning/demand-planning.selectors';
import {
  SET_DECIMAL_PLACES,
  SET_NUMERIC_SCALE,
  UPDATE_DEMAND_ANALYTIC_GROUPING,
  UPDATE_DEMAND_DATASET_YEAR_GROUPING,
  UPDATE_DEMAND_GROUPING,
  UPDATE_KPI_TRACKER_ANALYTIC_GROUPING, UPDATE_KPI_TRACKER_DATASET_YEAR_GROUPING, UPDATE_KPI_TRACKER_GROUPING
} from '../pages/demand-planning/demand-planning.actions';
import { select_tradeTermGroups } from '../pages/event-management/event-management.selectors';
import { SET_TRADE_TERM_GROUPS } from '../pages/event-management/event-management.actions';
import { select_scenarioScoreMetrics } from '../pages/planning-explorer/planning-explorer.selector';
import { SET_SCENARIO_SCORE_METRICS } from '../pages/planning-explorer/planning-explorer.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: NbAuthService,
    private readonly userService: UserData,
    private readonly router: Router,
    private readonly userStore: UserStore,
    private readonly store: Store,
    private readonly settingsService: SettingsService,
    private readonly abilityService: AbilityService,
    @Inject(NB_AUTH_OPTIONS) private readonly options: any,
  ) { }

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.DO_LOGIN),
      exhaustMap((action) =>
        this.service.authenticate(this.options.forms.login.strategy, action).pipe(
          map((result) => {
            if (!result.isSuccess()) {
              const message = result.getResponse().error?.message;
              const errors = message ? [message] : result.getErrors();
              return AuthActions.LOGIN_FAILED({ errors });
            }
            const token = result.getToken() as NbAuthOAuth2JWTToken;
            const redirect = result.getRedirect();
            const messages = result.getMessages();

            const accessTokenPayload = token.getAccessTokenPayload();

            // this.abilityService.defineAbilitiesFor(accessTokenPayload.role);
            this.abilityService.defineAbilitiesByPermissions(accessTokenPayload?.permissions);

            return AuthActions.LOGIN_SUCCESS({ token, redirect, messages });
          }),
          catchError((error) => of(AuthActions.LOGIN_FAILED(error))),
        ),
      ),
    );
  });

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DO_LOGOUT),
        switchMap(() =>
          this.service
            .logout(this.options.forms.login.strategy)
            .pipe(switchMap(() => {
              // this.abilityService.defineAbilitiesFor("");
              this.abilityService.defineAbilitiesByPermissions();

              return this.router.navigateByUrl('auth/login')
            })),
        ),
      );
    },
    { dispatch: false },
  );

  getCurrenntUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.GET_CURRENT_USER),
      switchMap(() =>
        this.userService.getCurrentUser().pipe(
          map((user) => {
            // TODO: migrate userStore to this AuthStore
            this.userStore.setUser(user);

            const {
              segments,
              kpiSegments,
              datasetYear,
              kpiDatasetYear,
              analytics,
              kpiAnalytics,
              decimalPlaces,
              numericScale,
              tradeTermGroups,
              scenarioScoreMetrics,
            } = user?.settings || {};
            if (segments && segments.length > 0) {
              this.store.dispatch(UPDATE_DEMAND_GROUPING({ data: segments as string[] }));
            }
            if (kpiSegments && kpiSegments.length > 0) {
              this.store.dispatch(UPDATE_KPI_TRACKER_GROUPING({ data: kpiSegments as string[] }));
            }
            if (datasetYear && datasetYear.length > 0) {
              this.store.dispatch(UPDATE_DEMAND_DATASET_YEAR_GROUPING({ data: datasetYear as string[] }));
            }
            if (kpiDatasetYear && kpiDatasetYear.length > 0) {
              this.store.dispatch(UPDATE_KPI_TRACKER_DATASET_YEAR_GROUPING({ data: kpiDatasetYear as string[] }));
            }
            if (analytics && analytics.length > 0) {
              this.store.dispatch(UPDATE_DEMAND_ANALYTIC_GROUPING({ data: analytics as string[] }));
            }
            if (kpiAnalytics && kpiAnalytics.length > 0) {
              this.store.dispatch(UPDATE_KPI_TRACKER_ANALYTIC_GROUPING({ data: kpiAnalytics as string[] }));
            }
            if (decimalPlaces && decimalPlaces > 0) {
              this.store.dispatch(SET_DECIMAL_PLACES({ decimalPlaces }));
            }
            if (numericScale) {
              this.store.dispatch(SET_NUMERIC_SCALE({ numericScale }));
            }
            if (tradeTermGroups) {
              this.store.dispatch(SET_TRADE_TERM_GROUPS({ tradeTermGroups }));
            }
            if (scenarioScoreMetrics) {
              this.store.dispatch(SET_SCENARIO_SCORE_METRICS({ scenarioScoreMetrics }));
            }

            return AuthActions.GET_CURRENT_USER_SUCCESS({ data: user });
          }),
          catchError((error) => of(AuthActions.GET_CURRENT_USER_FAILED({ error }))),
        ),
      ),
    );
  });

  // When login success, auto redirect to dashboard
  redirectWhenLoginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.LOGIN_SUCCESS),
        filter(({ redirect }) => !!redirect),
        switchMap(({ redirect }) => {

          return from(this.router.navigateByUrl(redirect))
        }),
      );
    },
    { dispatch: false },
  );

  // When fail to load current user, force logout
  logoutWhenFailToGetCurrentUser$ = createEffect(
    () => {
      return this.actions$.pipe(ofType(AuthActions.GET_CURRENT_USER_FAILED), map(DO_LOGOUT));
    },
    { dispatch: false },
  );

  updateUserSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(...SHOULD_UPDATE_USER_SETTINGS),
      concatLatestFrom((_) => [
        this.store.select(select_authUser),
        this.store.select(select_demandGroupings),
        this.store.select(select_kpiTrackerGroupings),
        this.store.select(select_demandDatasetYearGroupings),
        this.store.select(select_kpiTrackerDatasetYearGroupings),
        this.store.select(select_demandAnalyticGroupings),
        this.store.select(select_kpiTrackerAnalyticGroupings),
        this.store.select(select_decimalPlaces),
        this.store.select(select_numericScale),
        this.store.select(select_tradeTermGroups),
        this.store.select(select_scenarioScoreMetrics),
      ]),
      filter(
        ([action, user, segmentGroupings, kpiTrackerGroupings, datasetYearGroupings, kpiTrackerDatasetYearGroupings, analyticsGroupings, kpiTrackerAnalyticGroupings, decimalPlaces, numericScale, tradeTermGroups, scenarioScoreMetrics]: [any, any, any, any, any, any, any, any, number, string, string[], any]) =>
          [action, user, segmentGroupings, kpiTrackerGroupings, datasetYearGroupings, kpiTrackerDatasetYearGroupings, analyticsGroupings, kpiTrackerAnalyticGroupings, decimalPlaces, numericScale, tradeTermGroups, scenarioScoreMetrics].every(notNullOrUndefined)
      ),
      switchMap(([_, user, segmentGroupings, kpiTrackerGroupings, datasetYearGroupings, kpiTrackerDatasetYearGroupings, analyticsGroupings, kpiTrackerAnalyticGroupings, decimalPlaces, numericScale, tradeTermGroups, scenarioScoreMetrics]: [any, any, any, any, any, any, any, any, number, string, string[], any]) => {
        const settings = {
          ...user?.settings,
          segments: segmentGroupings,
          kpiSegments: kpiTrackerGroupings,
          analytics: analyticsGroupings,
          kpiAnalytics: kpiTrackerAnalyticGroupings,
          datasetYear: datasetYearGroupings,
          kpiDatasetYear: kpiTrackerDatasetYearGroupings,
          decimalPlaces,
          numericScale,
          tradeTermGroups,
          scenarioScoreMetrics,
        }

        return this.settingsService.updateCurrent(settings).pipe(
          map((data) => AuthActions.UPDATE_USER_SETTINGS_SUCCESS({ data })),
          catchError((error) => of(AuthActions.UPDATE_USER_SETTINGS_FAILED({ error })))
        )
      })
    );
  });
}
